import React, { useState, useEffect } from "react";
import DashboardHeader from "../../components/auth/DashboardHeader";
import Sidebar from "../../components/auth/Sidebar";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import PaymentLinkTable from "../../components/TransactionsDataTable";
import DashboardChart from "../../components/Chart/DashboardChart";
import { Loader } from "../../components/Loader";
import { toast } from "react-toastify";

const Dashboard = () => {
  const [fund, setFund] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [activeLoaders, setActiveLoaders] = useState(0); // New loading tracker state

  const navigate = useNavigate();

  //payment link transaction
  const [paymentTransactions, setPaymentTransactions] = useState([]);

  //show app list
  const [apps, setApps] = useState([]);

  // Helper function to manage loaders
  const startLoading = () => setActiveLoaders((prev) => prev + 1);
  const stopLoading = () => setActiveLoaders((prev) => Math.max(prev - 1, 0));
  const isLoading = activeLoaders > 0;

  async function fetchFundData() {
    try {
      startLoading();
      const url = `${process.env.REACT_APP_API_URL}/payment-link/merchant-fund-sum`;

      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        params: {
          currency: "usd",
        },
      });
      if (response.status === 200) {
        setFund(response?.data); // Ensure you are setting the correct data
      } else {
        toast.error(response.data);
      }
    } catch (error) {
      console.error("Error fetching fund data:", error);
    } finally {
      stopLoading();
    }
  }

  const fetchAppTransactions = async () => {
    try {
      startLoading();
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/merchant-app-tx/merchant-tx-list`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          params: {
            pageNo: currentPage,
            limitVal: 10,
          },
        }
      );
      setTransactions(response?.data?.data);
      setCurrentPage(response?.data?.currentPage);
      setTotalPages(response?.data?.totalPages);
    } catch (error) {
      console.error("Error fetching app transactions:", error);
    } finally {
      stopLoading();
    }
  };

  const fetchPaymentTransactions = async () => {
    try {
      startLoading();
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/payment-link/merchant-tx-list`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          params: {
            pageNo: currentPage,
            limitVal: 10,
          },
        }
      );
      setPaymentTransactions(response?.data?.data);
      setCurrentPage(response?.data?.currentPage);
      setTotalPages(response?.data?.totalPages);
    } catch (error) {
      console.error("Error fetching payment transactions:", error);
    } finally {
      stopLoading();
    }
  };

  const fetchApps = async () => {
    try {
      startLoading();
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/apps/get`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          params: {
            pageNo: currentPage,
            limitVal: 10,
          },
        }
      );
      setApps(response?.data?.data);
      setCurrentPage(response?.data?.currentPage);
      setTotalPages(response?.data?.totalPages);
    } catch (error) {
      console.error("Error fetching apps:", error);
    } finally {
      stopLoading();
    }
  };

  useEffect(() => {
    fetchApps();
    fetchAppTransactions();
    fetchPaymentTransactions();
  }, [currentPage]);

  useEffect(() => {
    fetchFundData();
  }, []);
  console.log("isLoading is : ", isLoading);

  return (
    <>
      <DashboardHeader />

      <div className="site-bg">
        <div className="dashborad-content-bg">
          <aside className="sidebar">
            <Sidebar />
          </aside>

          {isLoading ? (
            <Loader />
          ) : (
            <div className="dashborad-warper">
              <div className="statistics-payment-box">
                <div className="row">
                  <div className="col-md-9">
                    <div
                      className="white-bg statistics-content padd30"
                      style={{
                        width: "100%",
                      }}
                    >
                      <h2>Statistics</h2>
                      <div className="graph-image-box">
                        <DashboardChart />
                        {/* <img src="images/graph.png" alt="graph" /> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="dashborad-payment-bg">
                      <div className="dashborad-payment-box white-bg padd30">
                        <figure>
                          <img src="images/cryptos.png" alt="cryptos" />
                        </figure>
                        <figcaption>
                          <h4>Total Cryptos</h4>
                          <p>
                            <span>{fund?.usdTotal.toFixed(2)}</span> USD
                            {/* <i className="ti ti-currency-bitcoin" /> */}
                          </p>
                        </figcaption>
                      </div>
                      <div className="dashborad-payment-box white-bg padd30">
                        <figure>
                          <img src="images/bitcoin.png" alt="cryptos" />
                        </figure>
                        <figcaption>
                          <h4>My Cryptos</h4>
                          <div className="cryptocurrencies-btn">
                            <button
                              // to="/dashboard-manage-curriencies"
                              className="btn"
                              onClick={() => {
                                navigate("/dashboard-manage-curriencies", {
                                  state: {
                                    data: fund,
                                  },
                                });
                              }}
                            >
                              Show Native Balance
                            </button>
                          </div>
                        </figcaption>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="my-apps-box mar-top-bottom30">
                <div className="white-bg my-apps-content padd20">
                  <h2>My Apps</h2>

                  <div className="my-apps-list">
                    <ul>
                      {apps.length > 0 ? (
                        apps.map((app, index) => (
                          <li key={index}>
                            <Link to={`/apps-detail/${app._id}`}>
                              <div className="my-app-box">
                                <div className="my-app-head">
                                  <h3>{app?.name}</h3>
                                  <span>
                                    {moment(app?.createdAt).format(
                                      "DD MMMM YYYY"
                                    )}
                                  </span>
                                </div>
                                <div className="api-key-btn">
                                  <a>
                                    <i className="ti ti-key" />
                                    <span>API Key</span>
                                  </a>
                                </div>
                              </div>
                            </Link>
                          </li>
                        ))
                      ) : (
                        <li>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100%",
                              marginTop: "20px",
                            }}
                          >
                            <p>No apps found</p>
                          </div>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="transactions-list">
                <div className="white-bg my-apps-content padd20">
                  <h2>Recent Apps Transactions</h2>
                  <PaymentLinkTable
                    transactions={transactions}
                    isReceivedAmount={true}
                    isTxType={true}
                  />
                </div>
              </div>

              <div className="transactions-list">
                <div className="white-bg my-apps-content padd20">
                  <h2>Recent Payment Links</h2>
                  <PaymentLinkTable
                    transactions={paymentTransactions}
                    isCode={true}
                    isAmount={true}
                    isAmountAfterTax={true}
                    isTaxAmount={true}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Dashboard;
