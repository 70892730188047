import React, { useState, useEffect } from "react";
import DashboardHeader from "../../components/auth/DashboardHeader";
import Sidebar from "../../components/auth/Sidebar";
import axios from "axios";
import PaymentLinkTable from "../../components/TransactionsDataTable";
import { Loader } from "../../components/Loader";

const Transactions = () => {
  const [transactions, setTransactions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedCrypto, setSelectedCrypto] = useState("ALL"); // State for selected cryptocurrency
  const [isLoading, setIsLoading] = useState(false);

  const fetchTransactions = async () => {
    try {
      setIsLoading(true);
      await axios
        .get(`${process.env.REACT_APP_API_URL}/payment-link/merchant-tx-list`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          params: {
            pageNo: currentPage,
            limitVal: 10,
            symbol: selectedCrypto,
          },
        })
        .then((resp) => {
          console.log("res", resp);
          setTransactions(resp?.data?.data);
          setCurrentPage(resp?.data?.currentPage);
          setTotalPages(resp?.data?.totalPages);
        })
        .catch((error) => {
          console.error("Error in transactions list", error);
        });
    } catch (error) {
      console.error("Error fetching transactions:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTransactions();
  }, [currentPage, selectedCrypto]);

  const handlePageChange = (pageNo) => {
    if (pageNo > 0 && pageNo <= totalPages) {
      setCurrentPage(pageNo);
    }
  };

  const handleCryptoChange = (e) => {
    setSelectedCrypto(e.target.value);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <DashboardHeader />

      <div className="site-bg">
        <div className="dashborad-content-bg">
          <aside className="sidebar">
            <Sidebar />
          </aside>
          <div className="dashborad-warper">
            <div className="transactions-list">
              <div className="dashborad-head">
                <div className="breadcrumb-menu">
                  <p>All the transaction of payment links</p>
                </div>
                <div className="dashborad-head-right">
                  {/* <div className="year-select-box">
                    <select className="form-control">
                      <option value="">Chain Id</option>
                      <option value="97">97</option>
                    </select>
                  </div> */}

                  <div className="year-select-box">
                    <select
                      className="form-control"
                      value={selectedCrypto}
                      onChange={handleCryptoChange}
                    >
                      <option value="ALL">All</option>
                      <option value="USDT">USDT</option>
                      <option value="BNB">BNB</option>
                      <option value="ETH">ETH</option>
                      <option value="AVAX">AVAX</option>
                      <option value="USDC">USDC</option>
                      <option value="MATIC">MATIC</option>
                      <option value="TRX">TRX</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="white-bg my-apps-content padd20">
                <PaymentLinkTable
                  transactions={transactions}
                  isReceivedAmount={true}
                  isTaxAmount={true}
                  isAmountAfterTax={true}
                />
                <div className="pagination-box">
                  <ul>
                    <li>
                      <a
                        href=""
                        onClick={(e) => {
                          e.preventDefault();
                          handlePageChange(currentPage - 1);
                        }}
                        disabled={currentPage === 1}
                      >
                        <img src="images/left-arrow.png" alt="arrow" />
                      </a>
                    </li>
                    {[...Array(totalPages).keys()].map((pageNo) => (
                      <li
                        key={pageNo + 1}
                        className={currentPage === pageNo + 1 ? "active" : ""}
                      >
                        <a
                          href=""
                          onClick={(e) => {
                            e.preventDefault();
                            handlePageChange(pageNo + 1);
                          }}
                        >
                          {pageNo + 1}
                        </a>
                      </li>
                    ))}
                    <li>
                      <a
                        href=""
                        onClick={(e) => {
                          e.preventDefault();
                          handlePageChange(currentPage + 1);
                        }}
                        disabled={currentPage === totalPages}
                      >
                        <img src="images/right-arrow.png" alt="arrow" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Transactions;
