import React, { useState, useEffect } from "react";
import DashboardHeader from "../../components/auth/DashboardHeader";
import Sidebar from "../../components/auth/Sidebar";
import axios from "axios";
import { toast } from "react-toastify";
import { Loader } from "../../components/Loader";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const CustomerSupport = () => {
  const [user, setUser] = useState({});
  const [userId, setUserId] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    countryCode: "",
    contactNumber: "",
    description: "",
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setIsLoading(true);
    const fetchUserDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/merchants/user-profile`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setUser(response?.data);
        setUserId(response?.data?.data?._id);
        setFormData({
          name: response?.data?.data?.name,
          email: response?.data?.data.email,
          countryCode: response?.data?.data?.countryCode,
          contactNumber: response?.data?.data?.contactNumber,
        });
      } catch (error) {
        console.error("Error fetching adminn details:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchUserDetails();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validate = () => {
    const newErrors = {};
    if (!formData?.description) {
      newErrors.description = "Description is required";
    } else if (!formData?.description.trim()) {
      newErrors.description = "Description is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) {
      return;
    }
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/contact-us/add`, {
        ...formData,
      });
      toast.success("Request sent successfully!");
      setFormData((prevData) => ({
        ...prevData,
        description: "",
      }));
    } catch (error) {
      console.log("error", error);
      toast.error(error?.response?.data?.message[0]);
      console.error("Error updating details:", error);
    }
  };

  if (isLoading) {
    return <Loader />;
  }
  return (
    <>
      <DashboardHeader />

      <div className="site-bg">
        <div className="dashborad-content-bg">
          <aside className="sidebar">
            <Sidebar />
          </aside>
          <div className="dashborad-warper">
            <div className="transactions-list">
              <form onSubmit={handleFormSubmit}>
                <div className="white-bg my-apps-content padd20">
                  <div className="customer-support-box">
                    <figure>
                      <img src="images/support.png" />
                    </figure>
                    <figcaption>
                      <p>
                        Address you queries and get quick assisstance from us
                      </p>
                    </figcaption>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Name</label>
                        <div className="form-group-inner">
                          <div className="fild-icon">
                            <i className="ti ti-user" />
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Name"
                            value={formData?.name}
                            readOnly
                            style={{
                              backgroundColor: "#f5f5f5",
                              cursor: "not-allowed",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Contact Number</label>
                        <div className="form-group-inner">
                          <div className="fild-icon">
                            <i className="ti ti-phone" />
                          </div>
                          {/* <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Contact Number"
                            value={formData?.contactNumber}
                            readOnly
                            style={{
                              backgroundColor: "#f5f5f5",
                              cursor: "not-allowed",
                            }}
                          /> */}
                          <PhoneInput
                            country={"in"}
                            enableSearch={true}
                            placeholder="Enter Contact Number"
                            value={formData?.contactNumber}
                            inputStyle={{
                              width: "100%",
                              paddingLeft: "50px",
                              paddingRight: "15px",
                              borderRadius: "5px",
                              height: "43px",
                              border: "1px solid #ccc",
                              fontSize: "14px",
                              backgroundColor: "#f9f9f9",
                              cursor: "not-allowed",
                            }}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Email</label>
                        <div className="form-group-inner">
                          <div className="fild-icon">
                            <i className="ti ti-mail" />
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Email"
                            value={formData?.email}
                            readOnly
                            style={{
                              backgroundColor: "#f5f5f5",
                              cursor: "not-allowed",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Description</label>
                    <div className="form-group-inner">
                      <div className="fild-icon">
                        <i className="ti ti-info-circle" />
                      </div>
                      <textarea
                        type="text"
                        placeholder="Enter Description"
                        name="description"
                        className={`form-control ${
                          errors.description ? "is-invalid" : ""
                        }`}
                        value={formData.description}
                        onChange={handleInputChange}
                      />
                      {errors.description && (
                        <div className="invalid-feedback">
                          {errors.description}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="save-btn">
                    <button className="btn">Save</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerSupport;
