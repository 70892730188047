import React, { useState, useEffect } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { Link } from "react-router-dom";
import axios from "axios";

const HowItWorks = () => {
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [heading1, setHeading1] = useState("");
  const [description1, setDescription1] = useState("");
  const [heading2, setHeading2] = useState("");
  const [description2, setDescription2] = useState("");
  const [heading3, setHeading3] = useState("");
  const [description3, setDescription3] = useState("");
  const [heading4, setHeading4] = useState("");
  const [description4, setDescription4] = useState("");

  const fetchPages = async () => {
    try {
      await axios
        .get(`${process.env.REACT_APP_API_URL}/how-it-works/slug`, {
          params: { slug: "how-it-works" },
        })
        .then((res) => {
          console.log("res", res);
          setTitle(res?.data?.page?.title);
          setSubtitle(res?.data?.page?.subTitle);
          setHeading1(res?.data?.page?.heading1);
          setDescription1(res?.data?.page?.description1);
          setHeading2(res?.data?.page?.heading2);
          setDescription2(res?.data?.page?.description2);
          setHeading3(res?.data?.page?.heading3);
          setDescription3(res?.data?.page?.description3);
          setHeading4(res?.data?.page?.heading4);
          setDescription4(res?.data?.page?.description4);
        })
        .catch((error) => console.log(error));
    } catch (error) {
      console.error("Error fetching pages:", error);
    }
  };

  useEffect(() => {
    fetchPages();
  }, []);

  return (
    <>
      <Header />
      <div className="site-bg">
        <section className="inner-page-head">
          <div className="container">
            <h1>{title}</h1>
            <ul>
              <li className="home">
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/how-it-works">&rarr; How it Works</Link>
              </li>
            </ul>
          </div>
        </section>
        <section className="how-we-work-section how-it-work section-padding light-bg">
          <div className="container">
            <div className="row">
              <h3>{subtitle}</h3>
              <div className="col-md-7">
                <div className="row marTop40">
                  <div className="col-md-6">
                    <div className="how-work-box">
                      <figure>
                        <img src="images/work-icon1.png" alt="work" />
                      </figure>
                      <figcaption>
                        <h3>{heading1}</h3>
                        <p
                          dangerouslySetInnerHTML={{ __html: description1 }}
                        ></p>
                      </figcaption>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="how-work-box">
                      <figure>
                        <img src="images/work-icon3.png" alt="work" />
                      </figure>
                      <figcaption>
                        <h3>{heading2}</h3>
                        <p
                          dangerouslySetInnerHTML={{ __html: description2 }}
                        ></p>
                      </figcaption>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="how-work-box">
                      <figure>
                        <img src="images/work-icon2.png" alt="work" />
                      </figure>
                      <figcaption>
                        <h3>{heading3}</h3>
                        <p
                          dangerouslySetInnerHTML={{ __html: description3 }}
                        ></p>
                      </figcaption>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="how-work-box">
                      <figure>
                        <img src="images/work-icon4.png" alt="work" />
                      </figure>
                      <figcaption>
                        <h3>{heading4}</h3>
                        <p
                          dangerouslySetInnerHTML={{ __html: description4 }}
                        ></p>
                      </figcaption>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <div className="work-girl-img">
                  <img src="images/how-work.png" alt="girl" />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </>
  );
};

export default HowItWorks;
